import { Component, OnInit ,NgZone} from '@angular/core';
import { MainService } from '../../service/Main/main-service.service';
import { DataService } from '../../service/EventEmitter/data.service';
import { NgxSpinnerService } from "ngx-spinner";
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormsModule, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RouterLinkRendereComponent } from 'src/app/components/router-link-rendere/router-link-rendere.component';
import { ConfirmationDialogService } from '../../service/confirmation-service/confirmation-dialog.service';
import {SubscriptionDetailsComponent} from '../subscription-details/subscription-details/subscription-details.component';
import * as moment from 'moment';
@Component({
  selector: 'company-list-app',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.css']
})
export class CompanyListPageComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, 
    private router: Router, 
    public ngxSmartModalService: NgxSmartModalService,
    private dataService: DataService,
    private confirmationDialogService: ConfirmationDialogService,
    private toaster: ToastrService,
    private mainService: MainService,
    private modalService:NgbModal,
    public activeModal: NgbActiveModal,
    public fb: FormBuilder) { }

private gridApi;
private gridColumnApi;
domLayout='autoHeight';
isItemListUpdate = false;
searchText:string;
CompanyList: any = [];
Company: any = [];
dateList: any =[];
DateForm: FormGroup;
DatePrevious: any;
ExpiryDate: any;
Id: number;
ngOnInit(): void {
  this.getAllCompany();
  this.dateList = [
    { label : "7 Days", Id : 7},
    { label : "15 Days", Id : 15},
    { label : "20 Days", Id : 20}
  ]

}

// columnDefs = [
//   { headerName: 'Action',template: ` <button class="btn btn-danger" (click)="deleteCompany()">Delete</button>` },
//   {headerName: 'Company Name', field: 'CompanyName',width: 250},
//   { headerName: 'Package Type', field: 'PackageTypeName' },
//   { headerName: 'Package Price', field: 'PackagePrice' },
//   { headerName: 'Start Date', field: 'StartDateVw' },
//   { headerName: 'Expiry Date', field: 'ExpiryDateVw' },
//   { headerName: 'Payment Status', field: 'PaidStatus' },
//   { headerName: 'Payment Method', field: 'PaymentMethodName' },
//   { headerName: 'Status', field: 'ActiveStatus' },
//   { headerName: 'Bill To', field: 'BillToName' },
//   { headerName: 'Email', field: 'BillToEmail' },
//   { headerName: 'Phone No', field: 'PhoneNo' },
//   { headerName: 'Payment Date', field: 'PaymentDate' },
//   { headerName: 'Deposit Account', field: 'DepositAccountNumber' },
//   { headerName: 'Deposit Bank', field: 'DepositBankName' },
//   { headerName: 'Deposit Branch', field: 'DepositBranchName' },
//   { headerName: 'Swift Code', field: 'DepositBranchSwiftCode' },
//   { headerName: 'Expiry Status', field: 'ExpiryStatus' }
// ];

// defaultColDef = {
//   width: 250,
//   minWidth: 150,
//   sortable: true,
//   filter: true,
//   flex: 1,
// };

getAllCompany=()=>{

  this.spinner.show()
  this.CompanyList = []
  this.mainService.GetCompanyList().subscribe((x)=>{
  this.spinner.hide()
  this.CompanyList = x;
  this.Company=x;
  console.log(x);
  })
}


search = ()=>{
  this.gridApi.setQuickFilter(this.searchText);
}
openDetailsPopup(item)
{
  const modalRef = this.modalService.open(SubscriptionDetailsComponent,{ size: 'lg',backdrop: 'static', keyboard : false});
  modalRef.componentInstance.fromParent =item;
  modalRef.result.then((result) => {
               }, (reason) => {
                 console.log('Dismissed action: ' + reason);
               });

}

deleteCompany(e) {
  console.log(e);
  this.confirmationDialogService.confirm(e.CompanyName, 'Do you really want to delete the company and related all data ?')
  .then((confirmed) => {
  this.spinner.show();
  if (confirmed) {
  this.mainService.DeleteCompany(e.CompanyId).subscribe(x=>{
    this.spinner.hide();
    if(x.Success){
      this.toaster.success("Company has been deleted successfully.", 'Success!');
    }
    else{
      this.toaster.warning('You do not have permission to delete for previous subscriptions.')
    }
    this.getAllCompany();
    },
    err => {
    this.toaster.error("Please Contact support team");
    this.spinner.hide();  
  });
} else {
  this.spinner.hide();
}
}).catch(() =>{this.spinner.hide();} );
}
  
selectionChanged=(value)=>
  {
         
    console.log("selected value is"+value);
        var l=value.length-1;
        if(l>=0)
        {
            this.Company=  this.CompanyList.filter(x=> x.RemainingDays<=value[l]);
        }
        else
        {
          this.Company=  this.CompanyList;
        }
  }

  openDateIncreser = (item) => {
     this.DatePrevious =this.convertDateIntoDisplay(item.ExpiryDate);
     console.log(item);
     this.Id=item.Id; 
    this.ngxSmartModalService.create('DateChanger', 'content').open();
  }
  submitExtendedDate = (item) => {
     console.log(item);
     if (item==undefined){
      this.toaster.warning('Please fill all the required field !');
      return;
    }
     this.mainService.updateTrialPeriod(this.Id,item).subscribe((x)=>{
      if (x.Success)
      {
        this.toaster.success("Trial period has been increased successfully.");
        this.ngxSmartModalService.getModal('DateChanger').close();
        this.getAllCompany();
      }
    })
  }
  initDateFrom = () => {
    this.DateForm = this.fb.group({
      ExpiryDate: new FormControl()
     
    })
  }
 
  convertDateIntoDisplay = (date) => {
    let dateString = moment(date).format("Do MMM YYYY")
    return dateString;
  }

}