import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CouponsServiceService } from 'src/app/service/Coupons/coupons-service.service';

@Component({
  selector: 'app-coupon-add-modal',
  templateUrl: './coupon-add-modal.component.html',
  styleUrls: ['./coupon-add-modal.component.css']
})
export class CouponAddModalComponent implements OnInit {

  constructor(
    private couponService: CouponsServiceService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    public activeModal: NgbActiveModal
  ) 
  { }

  couponForm: FormGroup;
  today = new Date();

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm(){
    this.couponForm = new FormGroup({
      Id: new FormControl(0),
      Name: new FormControl('', [Validators.required]),
      UseLimit: new FormControl('', [Validators.required]),
      TotalUsed: new FormControl('', [Validators.required]),
      ExpiryDate: new FormControl(new Date()),
      DiscountAmount: new FormControl('', [Validators.required]),
      CreatedDate: new FormControl(new Date())
    });
  }

  addCoupon(){
    this.couponForm.get('TotalUsed').patchValue(this.couponForm.value.UseLimit);
    if(this.couponForm.invalid){
      this.toaster.warning("Please provide all required inputs.");
      return;
    }
    if(Number(this.couponForm.value.DiscountAmount) <= 0){
      this.toaster.warning("Please provide all required inputs.");
      return;
    }
    this.couponForm.value.ExpiryDate.setHours(23, 59, 59);
    this.spinner.show();
    this.couponService.AddCoupon(this.couponForm.value).subscribe(x => {
      this.spinner.hide();
      if(x.Success){
        this.toaster.success(x.Message);
        location.reload();
        this.activeModal.close();
      }else{
        this.toaster.error(x.Message);
      }
    })
  }

}
