<div class="modal-header">
    <h4 class="modal-title font-weight-bold" style="font-size: 17px;">Subscription Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
        <span aria-hidden="true" style="font-size: 25px">&times;</span>
    </button>
</div>
<hr>
<div class="modal-body" style="padding: 0em 1em 1em 1em!important;">

    <div class="row" style="margin:0px; padding: 20px;">
        <table style="color: #7F7F7F; font-size: 14px;">
            <tr>
                <td>
                    Company Name :
                </td>
                <td>
                    {{fromParent.CompanyName}}
                </td>
            </tr>
            <tr>
                <td>
                    Package Type :
                </td>
                <td>
                    {{fromParent.PackageTypeName}}
                </td>
            </tr>
            <tr>
                <td>
                    Package Price
                </td>
                <td>
                    {{fromParent.PackagePrice}}
                </td>
            </tr>
        </table>
            <table style="color: #7F7F7F; font-size: 14px;">
            <tr>
                <td>
                    Start Date :
                </td>
                <td>
                    {{fromParent.StartDateVw}}
                </td>
            </tr>
            <tr>
                <td>
                    Expiry Date :
                </td>
                <td>
                    {{fromParent.ExpiryDateVw}}
                </td>
            </tr>
            <tr>
                <td>
                    Payment Status :
                </td>
                <td>
                    {{fromParent.PaidStatus}}
                </td>
            </tr>
        </table>
    </div>
    <div *ngFor="let file of AttachmentList" id="demo{{file.Id}}" 
        (mouseout)="mouseOut(file.Id)" class="col-3">
        <div id="hover{{file.Id}}" class="alls attachment-div" style="width: 700px;">
            
                <div *ngIf="isPdfFile(file)">
                    <pdf-viewer  [src]="src"
                    [render-text]="true"
                    [fit-to-page]="true"
                    [original-size]="false"
                    [zoom]="1">
                </pdf-viewer>
            </div>
                <!-- <img class="col-md-12" width="30%" style="border:none; margin:0 auto;" src="https://image.flaticon.com/icons/png/512/179/179483.png" alt="..." class="img-thumbnail"> -->
               <div *ngIf="!isPdfFile(file)">
                <img class="col-md-12" width="30%" style="border:none; margin:0 auto;width: 700px;" src={{fileUrl}}{{file.BlobName}}
                    alt="..." class="img-thumbnail">
                <p style="margin: 0 auto;width: 100%;text-align: center; font-size: 13px;">{{file.FileName}}</p>
            </div>
            
        </div>
        
            <div style="text-align: center; width: 100%;">
                <a class="option-icon-color" (click)="view(file)"><i
                        class="material-icons-outlined">insert_drive_file</i></a>
                <a href={{fileUrl}}{{file.BlobName}} download={{file.BlobName}} class="option-icon-color"><i
                        class="material-icons">file_download</i></a>
                <a (click)="print(file,null,isPdfFile(file))" class="option-icon-color"><i
                        class="material-icons-outlined">print</i></a>
                <a (click)="share(file)" class="option-icon-color" data-toggle="modal" data-target="#shareModal"><i
                        class="material-icons-outlined">share</i></a>
            </div>
        </div>
 
</div>
<ngx-smart-modal #AttachmentViewer identifier="AttachmentViewer">
    <div class="pb-4">
        <span class="modalHeaderText pl-2" class="attachment-header">{{selected?.FileName}}</span>
        <hr class="notShow">
        <div class="row pr-2 pl-2">
            <div class="col-md-12 notShow">
                <div class="row">

                    <div class="col-md-8 " style="text-align: right;">
                        <a><span class="option-icon-color" (click)="zoomIn()"><i
                                    class="material-icons borderradiusleft attachmenticon">zoom_in</i></span></a>
                        <a><span class="option-icon-color" (click)="zoomOut()"><i
                                    class="material-icons attachmenticon">zoom_out</i></span></a>
                        <a [hidden]="isPdf"><span class="option-icon-color" (click)="rotate('left')"> <i
                                    class="material-icons attachmenticon">rotate_left</i> </span></a>
                        <a [hidden]="isPdf"><span class="option-icon-color" (click)="rotate('right')"> <i
                                    class="material-icons attachmenticon">rotate_right</i> </span></a>
                        <a href={{fileUrl}}{{selected?.BlobName}} download={{selected?.BlobName}}
                            class="option-icon-color"><i class="material-icons attachmenticon">file_download</i></a>
                        <a><span (click)="print(selected,'contentToPrint',isPdf)" class="option-icon-color"><i
                                    class="material-icons-outlined attachmenticon">print</i></span></a>
                        <a><span (click)="share(selected)" class="option-icon-color" data-toggle="modal"
                                data-target="#shareModal"><i
                                    class="material-icons-outlined borderradiusright border-right attachmenticon">share</i></span></a>
                    </div>
                </div>
            </div>
            <div id="contentToPrint" class="col-md-12">
                <!-- <div [hidden]="!isPdf"> 
                    <iframe id="iframepdf" src={{src}}></iframe> 
                      <embed width="100%" height="1000px" src={{src}}/> 
                     <pdf-viewer [src]="src"
                        [render-text]="true"
                        [fit-to-page]="true"
                        [zoom]="zoom"
                        style="display: block;">
                    </pdf-viewer> 
                </div>  -->
                <div>
                    <div style="overflow: overlay;">
                        <img id="image" style="width: 100%;" src={{src}} />
                    </div>
                </div>
            </div>
        </div>

    </div>
</ngx-smart-modal>
<hr />
<div class="col-sm-12" [hidden]="fromParent.PaidStatus=='Paid'">

    <button type="button" (click)="confirmPayment()" class="eCount-def-btn  ml-4 p-save-button mb-4"
        style="float: right;">Confirm Payment</button>

</div>

