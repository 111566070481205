import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Coupons } from 'src/app/models/coupons.model';
import { CouponsServiceService } from 'src/app/service/Coupons/coupons-service.service';
import { CouponAddModalComponent } from '../coupan-add-modal/coupon-add-modal/coupon-add-modal.component';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-coupon-settings',
  templateUrl: './coupon-settings.component.html',
  styleUrls: ['./coupon-settings.component.css']
})
export class CouponSettingsComponent implements OnInit {

  couponsList: Coupons[] = [];

  constructor(
    private couponsService: CouponsServiceService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
  ) { 

  }

  ngOnInit(): void {
    this.couponsService.GetAllCouponInformations().subscribe(x => {
      this.couponsList = x;
    })
  }

  deleteCoupon(item){
    this.spinner.show();
    this.couponsService.DeleteCoupon(item).subscribe(x => {
      this.spinner.hide();
      if(x.Success){
        this.toaster.success(x.Message);
        location.reload();
      }else{
        this.toaster.error(x.Message);
      }
    })
  }

  openAddCouponModal()
  {
    const modalRef = this.modalService.open(CouponAddModalComponent,{ size: 'md',backdrop: 'static', keyboard : false});
    modalRef.result.then((result) => {
    }, (reason) => {
      console.log('Dismissed action: ' + reason);
    });
  }



}
