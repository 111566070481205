
<app-dashboard>
    <app-page-header-title
        mainTitle="Company List"
        subTitle="Home"
        backURL="/">
    </app-page-header-title>
    <div class="row mb-4">
        <span class="mt-2 ml-4">Expired In : </span>
        <div style="margin-left: 5px; " class="col-sm-4">
            <ng-select [items]="dateList" placeholder="Select Items" 
                class="customSaleItem" [clearable]="false" bindLabel="label" bindValue="Id"
                 [(ngModel)]="dateList.Id" (change)="selectionChanged(dateList.Id)" [multiple]="true">
    
            </ng-select>
          
        </div>
    </div>
    <div class="row" id="list">
        <div class="col-sm-12 col-md-12">
            <table class="table  f-ss-14px">
                <thead>
                    <tr class="tableHeader">

                        <th scope="col-sm-1" class="table-header-color">Company Name</th>
                        <th scope="col-sm-1" class="table-header-color">Package Type</th>
                        <th scope="col-sm-1" class="table-header-color">Package Price</th>
                        <th scope="col-sm-1" class="table-header-color">Coupon Name</th>
                        <th scope="col-sm-2" class="table-header-color">Coupon Discount Amount(%)</th>
                        <th scope="col-sm-2" class="table-header-color">Total Amount After Discount</th>
                        <th scope="col-sm-1" class="table-header-color">Start Date</th>
                        <th scope="col-sm-1" class="table-header-color">Expired Report</th>
                        <th scope="col-sm-1" class="table-header-color">Last Login Time</th>
                        <th scope="col-sm-1" class="table-header-color"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of Company">
                        <td>
                            {{item.CompanyName}}
                        </td>
                        <td class="">{{item.PackageTypeName}}</td>
                        <td class="">{{item.PackagePrice}}</td>
                        <td class="">{{item?.Coupons?.Name}}</td>
                        <td class="">{{item?.Coupons?.DiscountAmount}}</td>
                        <td class="">{{item.TotalAmount}}</td>
                        <td class="">{{item.StartDateVw}}</td>
                        <td class="">{{item.RemaingDaysView}}</td>
                        <td>{{item.RecentLoginTimeVw}}</td>
                        <td class="">
                            <div style="padding: 7px 10px; color: #c7c2c2;">
                                <div class="dropdown mr-4">
                                    <div role="button" style="cursor: pointer;" data-toggle="dropdown" aria-haspopup="false"
                                        aria-expanded="false">
                                        <i class="icon-more float-right"></i>
                                    </div>
                                    <div class="dropdown-menu context-menu-wrapper" aria-labelledby="navbarDropdown"
                                        style="width: 100%;">

                                        <div class="context-menu-item pb-3" >
                                            <i class="icon-book"></i> <span class="context-menu-text" (click)="openDetailsPopup(item)">Details</span>
                                        </div>

                                        <div class="context-menu-item pb-3" (click)="deleteCompany(item)">
                                            <i class="icon-trash"></i><span class="context-menu-text">Delete</span>
                                        </div>
                                        <div class="context-menu-item pb-3" (click)="openDateIncreser(item)">
                                            <i class="fas fa-calendar-alt"></i><span class="context-menu-text">Date Extend</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <ngx-smart-modal #DateChanger identifier="DateChanger">
        <div class="pb-4">
            <span class="modalHeaderText pl-2">Date Picker</span>
        <hr>
        <div class="row pr-2 pl-2">
            <div class="col-md-12">
                <div>
                    <div>
                        <span class="formTitle">Previous Date</span>
                        <input class="form-control" type="text" [(ngModel)]="DatePrevious">
                    </div>
                       
                        <div class="form-group">
                            <span class="formTitle">New Date</span>
                            <input type="date" [(ngModel)]="ExpiryDate" class="form-control mt-1"  placeholder="">
                        </div>                
                       <div class="form-group pt-4" style="float: right;">
                            <button  class="eCount-def-btn ml-4 pl-4 pr-4"(click)="submitExtendedDate(ExpiryDate)">Submit</button>
                        </div>
                   
        
                </div>
            </div>
        </div>
        
    </div>
</ngx-smart-modal>
</app-dashboard>